import React from 'react';

function CoursesFooter() {
  return (
    <footer>
      {/* //   <h4>Roadmap</h4>
    //   <ul
    //     style={{
    //       listStyleType: 'none',
    //     }}
    //   >

    //     <li>
    //       <span role="img" aria-label="white-sqaure">
    //         ⬜
    //       </span>{' '}
    //       Support restricted access
    //     </li>
    //   </ul> */}
    </footer>
  );
}

export default CoursesFooter;
